/* eslint-disable react/no-multi-comp */
import React from 'react';
import { styled } from '@neui/core';
import { Box, HStack, VStack } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';
import { ICallToAction } from '@cds/search-client';
import { widgetToTrackType } from '@utils/snowplowTracking';
import { TOP_WIDGET_TYPES, WIDGET_TYPES } from '@utils/WidgetChecker';

import {
  CorporateAppDesktop,
  MobileAppAndroid,
  MobileAppDesktop,
  MobileAppIos,
} from './MobileAppWidget';
import OnlineBankWidget from './OnlineBankWidget';
import TelefonWidget from './TelefonWidget';
import { WidgetBanner } from './WidgetBanner';

const WidgetBox = ({
  headline,
  children,
}: {
  headline?: string;
  children: React.ReactNode;
}) => {
  return (
    <VStack spacing={24} alignItems="flex-start">
      {headline && <Headline type="h4">{headline}</Headline>}
      {children}
    </VStack>
  );
};

const SimpleWidget = ({
  widgetType,
  ctas,
}: {
  widgetType: TOP_WIDGET_TYPES;
  ctas: ICallToAction[];
  secondary: boolean;
}): JSX.Element => {
  const widgetName = widgetToTrackType[widgetType];

  switch (widgetType) {
    case WIDGET_TYPES.MOBILEAPPANDROID:
      return <MobileAppAndroid ctas={ctas} widgetName={widgetName} />;

    case WIDGET_TYPES.MOBILEAPPIOS:
      return (
        <WidgetBox>
          <MobileAppIos ctas={ctas} widgetName={widgetName} />
        </WidgetBox>
      );

    case WIDGET_TYPES.ONLINEBANKING:
      return <OnlineBankWidget ctas={ctas} widgetName={widgetName} />;

    case WIDGET_TYPES.TELEFONBUTTON:
      return (
        <TelefonWidget ctas={ctas} isMobile={true} widgetName={widgetName} />
      );

    case WIDGET_TYPES.TELEFONQRCODE:
      return (
        <TelefonWidget ctas={ctas} isMobile={false} widgetName={widgetName} />
      );

    case WIDGET_TYPES.BANNER:
      return (
        <Box css={{ width: '100%' }}>
          <WidgetBanner ctas={ctas} widgetName={widgetName} />
        </Box>
      );

    default:
      return <></>;
  }
};

SimpleWidget.displayName = 'SimpleWidget';

export const WidgetRenderer = ({
  ctas,
  widgetsToRender,
  articleUrl,
}: {
  ctas: ICallToAction[];
  widgetsToRender: TOP_WIDGET_TYPES[];
  articleUrl: string;
}): JSX.Element => {
  // The Mobile App Desktop has a special container case which we have to handle individually
  const isMobileAppDesktop =
    widgetsToRender.includes(WIDGET_TYPES.MOBILEAPPIOSQRCODE) &&
    widgetsToRender.includes(WIDGET_TYPES.MOBILEAPPANDROIDQRCODE);

  const isCorporateAppDesktop =
    widgetsToRender.includes(WIDGET_TYPES.CORPORATEAPPIOSQRCODE) &&
    widgetsToRender.includes(WIDGET_TYPES.CORPORATEAPPANDROIDQRCODE);

  return (
    <>
      <HStack spacing={16} data-cy={'top-widgets'}>
        {ctas &&
          (isMobileAppDesktop ? (
            <VStack spacing={32}>
              <Separator />
              <MobileAppDesktop ctas={ctas} articleUrl={articleUrl} />
              <Separator />
            </VStack>
          ) : isCorporateAppDesktop ? (
            <VStack spacing={32}>
              <Separator />
              <CorporateAppDesktop ctas={ctas} articleUrl={articleUrl} />
              <Separator />
            </VStack>
          ) : (
            widgetsToRender.map((w, i) => (
              <SimpleWidget
                key={i}
                widgetType={w}
                ctas={ctas}
                secondary={i > 0}
              />
            ))
          ))}
      </HStack>
    </>
  );
};

WidgetRenderer.displayName = 'WidgetRenderer';

const Separator = styled(Box, {
  height: 1,
  backgroundColor: '$colors$text-disabled',
  width: '100%',
});
