/* eslint-disable react/no-multi-comp */
import React from 'react';
import { styled } from '@neui/core';
import { Flex, HStack, VStack } from '@neui/layout';
import { Headline, Typography } from '@neui/styleguide-commerzbank';
import { ICallToAction } from '@cds/search-client';
import { WIDGET_TYPES } from '@utils/WidgetChecker';
import { WidgetName, widgetToTrackType } from '@utils/snowplowTracking';
import { QrCodeWidget } from '@components/Widgets/QrCodeWidget';
import { Image } from '@components/Image';
import { useTranslation } from '@utils/i18n';
import { useMakeLink } from '@components/Link';
import { getWidgetsBySlug } from '@utils/widgets/widgetData';

import { WidgetActionButton } from './WidgetActionButton';

export const MobileAppAndroid = ({
  ctas,
  widgetName,
}: {
  ctas: ICallToAction[];
  widgetName: WidgetName;
}): JSX.Element => {
  return (
    <>
      {ctas
        ?.filter((cta) => cta.category === 'android application')
        .map((cta, idx) => (
          <WidgetActionButton
            key={idx}
            url={cta.url}
            label={'PhotoTAN App im Google Play Store'}
            icon={'brands___google'}
            widgetName={widgetName}
            variant="solid"
          />
        ))}
    </>
  );
};

export const MobileAppIos = ({
  ctas,
  widgetName,
}: {
  ctas: ICallToAction[];
  widgetName: WidgetName;
}): JSX.Element => {
  return (
    <>
      {ctas
        ?.filter((cta) => cta.category === 'ios application')
        .map((cta, idx) => (
          <WidgetActionButton
            key={idx}
            url={cta.url}
            label={'PhotoTAN App im iOS App Store'}
            icon={'brands___apple'}
            widgetName={widgetName}
            variant="solid"
          />
        ))}
    </>
  );
};

type AppDesktopProps = {
  headline: string;
  leadText: string;
  ctas: Array<ICallToAction>;
  articleUrl: string;
  illustration: string;
};

const AppDesktop = ({
  headline,
  leadText,
  articleUrl,
  illustration,
}: AppDesktopProps): JSX.Element => {
  const makeLink = useMakeLink();

  const widgetData = getWidgetsBySlug(articleUrl);

  return (
    <>
      <HStack spacing={32}>
        <VStack spacing={16}>
          <Headline type={'h5'} renderAs="span">
            {headline}
          </Headline>
          <Typography>{leadText}</Typography>
          <Wrapper>
            {widgetData.map((data, idx) => (
              <QrCodeWidget
                key={idx}
                label={data.label}
                id={data.id}
                widgetName={
                  widgetToTrackType[
                    data.icon === 'brands___apple'
                      ? WIDGET_TYPES.MOBILEAPPIOS
                      : WIDGET_TYPES.MOBILEAPPANDROID
                  ]
                }
                logoImage={makeLink({
                  href:
                    data.icon === 'brands___apple'
                      ? '/icons/apple-logo.svg'
                      : '/icons/google-logo.svg',
                  alwaysPrependBasePath: true,
                })}
              />
            ))}
          </Wrapper>
        </VStack>
        <Image src={illustration} alt="" />
      </HStack>
    </>
  );
};

export const MobileAppDesktop = ({
  ctas,
  articleUrl,
}: {
  ctas: Array<ICallToAction>;
  articleUrl: string;
}): JSX.Element => {
  return (
    <AppDesktop
      headline="photoTAN App herunterladen"
      leadText="Scannen Sie den QR-Code, um die PhotoTAN App im App Store oder Google Play Store herunterzuladen."
      ctas={ctas}
      articleUrl={articleUrl}
      illustration="/icons/phototan-illustration.svg"
    />
  );
};

export const CorporateAppDesktop = ({
  ctas,
  articleUrl,
}: {
  ctas: Array<ICallToAction>;
  articleUrl: string;
}): JSX.Element => {
  const { $t } = useTranslation();

  return (
    <AppDesktop
      headline={$t('WIDGET_CORPORATE_APP_HEADLINE')}
      leadText={$t('WIDGET_CORPORATE_APP_TEXT')}
      ctas={ctas}
      articleUrl={articleUrl}
      illustration="/icons/corporate-app-illustration.svg"
    />
  );
};

const Wrapper = styled(Flex, {
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '24px 32px',
  flexWrap: 'wrap',
  textAlign: 'center',
});
