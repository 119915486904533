import { AsProps, CSSProps, styled } from '@neui/core';
import { Box } from '@neui/layout';
import { forwardRef, HTMLAttributes } from 'react';

export type HeaderContainerProps = HTMLAttributes<HTMLDivElement> &
  AsProps &
  CSSProps & {};

const Spacer = styled('div', {
  height: 64,
  '@sm': {
    height: 80,
  },
});

const Container = styled(Box, {
  position: 'fixed',
  width: '100%',
  zIndex: 2,
  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.36)',
  backgroundColor: '$colors$secondary !important',
  paddingY: 16,
  paddingX: 28,
  '&:focus, &:focus-visible': {
    outline: 'none',
  },
  '@sm': {
    paddingY: 24,
    paddingX: 32,
  },
});

export const HeaderContainer = forwardRef<HTMLDivElement, HeaderContainerProps>(
  ({ children, ...rest }, forwardedRef) => {
    return (
      <>
        <Spacer />
        <Container ref={forwardedRef} {...rest} as={'header'}>
          {children}
        </Container>
      </>
    );
  },
);
HeaderContainer.displayName = 'HeaderContainer';
