/**
 * DO NOT CHANGE THIS GENERATED FILE MANUALLY
 */
const object___checklistPencil = ({
  id,
  svgAttrs = {},
  title,
  variant,
  size,
}) => {
  const idTitle = `${id}-title`;
  if (variant === 'outline' && size === 24)
    return `<svg ${Object.entries(svgAttrs)
      .map(([key, value]) => `${key}="${value}"`)
      .join(
        ' ',
      )} id="${id}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">${title ? `<title id="${idTitle}">${title}</title>` : ''}<path fill-rule="evenodd" clip-rule="evenodd" d="M17 3V10.5L19 7V1H3V23H12V21H5V3H17ZM7 8.75865L8.17723 7.58142L9.92648 9.33897L13.3089 4L14.71 4.88706L10.2249 11.9753L7 8.75865ZM15 13H7V15H15V13ZM7 17H13V19H7V17Z" fill="#002E3C"/><path fill-rule="evenodd" clip-rule="evenodd" d="M22.5001 6.75L19.4601 5L11 20L14.14 21.57L22.5001 6.75Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.29 21.7L14 20.55L14.01 23L16.29 21.7Z" fill="#002E3C"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.03 10.75L19.99 9L14.53 18.58L17.67 20.15L23.03 10.75Z" fill="#002E3C"/></svg>`;
  else throw new Error('Unsupported combination of variant and size');
};
object___checklistPencil.iconName = 'object___checklistPencil';

export { object___checklistPencil };
