import widgets from './widgets.json';

type WidgetData = {
  label: string;
  url: string;
  id: string;
  icon: string;
};

type Widget = {
  '@type': string;
  id: string;
  category: string;
  url?: string;
  description: string;
  icon: string;
  subjectOf?: {
    '@type': string;
    telephone: string;
  };
  badgeIcon?: string;
  articleUrl: string;
};

const hasWidgets = (k: string): k is keyof typeof widgets => k in widgets;

export function getWidgetsBySlug(slug: string): WidgetData[] {
  const widgetData = hasWidgets(slug) ? widgets[slug] : [];

  return widgetData.map((data) => {
    return {
      label: data.description,
      url: data.url || '',
      id: data.id,
      icon: data.icon,
    };
  });
}

export const getWidgetDataById = (id: string): Widget | null => {
  for (const articleUrl of Object.keys(widgets) as Array<
    keyof typeof widgets
  >) {
    const matchingWidget = widgets[articleUrl].find(
      (widget) => widget.id === id,
    );
    if (matchingWidget) {
      return {
        ...matchingWidget,
        articleUrl,
      };
    }
  }
  return null;
};

export const getWidgetNameById = (id: string): string | null => {
  const category = getWidgetDataById(id)?.category;

  if (category === 'ios application') {
    return 'download_ios';
  } else if (category === 'android application') {
    return 'download_android';
  } else if (category === 'online banking') {
    return 'in_online_banking';
  } else if (category === 'customer service') {
    return 'am_telefon';
  } else if (category === 'banner') {
    return 'banner';
  } else if (category === 'ios corporate application') {
    return 'download_corporate_ios';
  } else if (category === 'android corporate application') {
    return 'download_corporate_android';
  } else if (category === 'directions') {
    return 'directions';
  }

  return null;
};
