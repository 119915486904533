/* eslint-disable react-hooks/exhaustive-deps */
import {
  interaction___close,
  interaction_arrows_arrowRight,
  Typography,
} from '@neui/styleguide-commerzbank';
import { useRouter } from 'next/router';
import React from 'react';
import { styled } from '@neui/core';
import { Box, VStack } from '@neui/layout';
import {
  createPortalEntity,
  createSearchEntity,
  useTracker,
} from '@utils/snowplowTracking';
import { Breadcrumbs } from '@components/neui-components/molecules/Breadcrumbs';
import { ClickItem } from '@components/neui-components/atoms/ClickItem';
import { SearchCtx } from '@components/Search/CdsSearch';
import { useTranslation } from '@utils/i18n';
import { useMakeLink } from '@components/Link';
import { getSearchParameters, shorten } from '@components/Search/helpers';
import { BreadcrumbsItem } from '@components/neui-components/atoms/BreadcrumbsItem';
import { BannerTextBadge } from '@components/neui-components/atoms/BannerTextBadge';
import { BannerTextBadgeProps } from '@components/neui-components/atoms/BannerTextBadge';
import { XColumnsGridItem } from 'page-templates/XColumnsGridItem';
import { MostReadArticleType } from 'pages';
import SearchInteractionIcon from '@components/icons/SearchInteractionIcon';
import { Underline } from '@components/neui-components/atoms/Underline';
import { NoResultSpacing, StyledBox } from '@components/CdsStyledComponents';
import ErrorIcon from '@components/icons/ErrorIcon';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { isInlineWidget, isTopWidget } from '@utils/WidgetChecker';
import { InlineWidgetRenderer } from '@components/Widgets/InlineWIdgetRenderer';
import { Link } from '@components/Link';
import { useRuntimeSettings } from '@utils/config';
import { getBadgeAttributes } from '@utils/badges';
import {
  GA4PageType,
  GA4SearchType,
  GA4TrackInternalSearch,
  GA4TrackSearchResultClick,
} from '@utils/tracking';
import { ChatEntryPoint } from '@components/Chat/ChatEntryPoint';
import { selectChatProps, useChatStore } from '@components/Chat/store/store';

import { WidgetRenderer } from '../Widgets/WidgetRenderer';
import { SuggestionResultType } from '.';
import { SuggestionChips } from './SuggestionChips';
import { TopicPageList } from './TopicPageList';
import { TopArticles } from './TopArticles';

interface SearchResultsWrapperProps {
  totalHits?: number;
  searchResults: Array<SuggestionResultType>;
  typoCorrection?: string;
  alternativeQuery?: string;
  pageType: GA4PageType;
  searchResultsTitle: string;
  mostSearchedTerms?: string[];
  mostReadArticles: MostReadArticleType[];
  referringPortal?: string;
  page: number;
  latestSuggestion: string;
  isSearchDown: boolean;
  executeSearch: (
    query: string,
    page: number,
    correctTypos: boolean,
    searchType: GA4SearchType,
  ) => Promise<void>;
  resultsTitle?: React.Ref<HTMLElement>;
}

export const CdsSearchResultsWrapper = ({
  totalHits,
  searchResults,
  typoCorrection,
  alternativeQuery,
  pageType,
  searchResultsTitle,
  mostReadArticles,
  referringPortal,
  page,
  latestSuggestion,
  isSearchDown,
  executeSearch,
  resultsTitle,
}: SearchResultsWrapperProps): JSX.Element => {
  const {
    search: { topSearchTerms, badgeRules },
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
    staticContent: {
      showMostReadArticles,
      showTopicPages,
      showBreadcrumbs,
      homeBreadrumbKey,
    },
  } = useRuntimeSettings();
  const { $t, language } = useTranslation();
  const { trackButtonClick, trackSearch } = useTracker(
    CdsSearchResultsWrapper.name,
  );
  const router = useRouter();
  const makeLink = useMakeLink();
  const { searchType } = getSearchParameters(router);
  const areThereResults = searchResults.length > 0;

  let resultText;

  if (isSearchDown) {
    // case 5: Error message when search is down
    resultText = (
      <VStack
        flexDirection={{ base: 'column', md: 'row-reverse' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingBottom={{ base: '$layout-2', md: '$layout-3' }}
        spacing="4px"
      >
        <Box>
          <ErrorIcon />
        </Box>
        <StyledBox>
          <VStack
            paddingBottom={4}
            marginTop={{ base: 0, md: '$layout-5' }}
            marginBottom={{ base: 0, md: '$layout-5' }}
          >
            <ResultsTitle
              size={4}
              weight={'medium'}
              renderAs={'h1'}
              css={{ marginBottom: '8px' }}
              tabIndex={-1}
              ref={resultsTitle}
            >
              {$t('SEARCH_IS_DOWN')}
            </ResultsTitle>
            <Typography size={7} weight={'book'} css={{ marginBottom: '16px' }}>
              {$t('SEARCH_IS_DOWN_SUBLINE')}
            </Typography>
            <IconLink
              iconPosition="right"
              icon={interaction_arrows_arrowRight}
              data-cy={'top-article'}
              href="/"
            >
              Zum Serviceportal
            </IconLink>
          </VStack>
        </StyledBox>
      </VStack>
    );
  } else if (areThereResults) {
    const enoughResults = totalHits !== undefined && totalHits > 5;
    if (alternativeQuery !== undefined) {
      resultText = (
        <VStack paddingBottom={{ base: 24, md: 32 }} spacing="4px">
          <ResultsTitle
            size={4}
            weight={'medium'}
            renderAs={'h1'}
            tabIndex={-1}
            ref={resultsTitle}
          >
            {`${totalHits} ${$t('SEARCH_RESULTS_FOR')} "${searchResultsTitle}"`}
          </ResultsTitle>
          <ResultsSuggestion size={7} weight={'book'}>
            {$t('SEARCH_INSTEAD_FOR')}
            &ldquo;
            <Link
              underlined={true}
              renderAs={'button'}
              weight={'medium'}
              size={7}
              onClick={() => {
                const searchType = 'Unknown';
                enableGA4Tracking &&
                  GA4TrackInternalSearch(
                    alternativeQuery ?? '',
                    searchType,
                    pageType,
                  );
                executeSearch(alternativeQuery, 1, false, searchType);
                trackButtonClick?.(alternativeQuery, 'search_term', []);
              }}
            >
              {alternativeQuery}
            </Link>
            &rdquo;
          </ResultsSuggestion>
        </VStack>
      );
    }
    // cases when there is typo correction suggestion
    else if (typoCorrection !== undefined) {
      resultText = (
        <VStack paddingBottom={{ base: 24, md: 32 }} spacing="4px">
          {/* case 2: immediate typo correction*/}
          {typoCorrection !== searchResultsTitle && enoughResults && (
            <>
              <ResultsTitle
                size={4}
                weight={'medium'}
                renderAs={'h1'}
                tabIndex={-1}
                ref={resultsTitle}
              >
                {`${totalHits} ${$t('SEARCH_RESULTS_FOR')} "${typoCorrection}"`}
              </ResultsTitle>
              <ResultsSuggestion size={7} weight={'book'}>
                {$t('SEARCH_INSTEAD_FOR')}
                &ldquo;
                <Link
                  underlined={true}
                  renderAs={'button'}
                  weight={'medium'}
                  size={7}
                  onClick={() => {
                    const searchType = 'Unknown';
                    enableGA4Tracking &&
                      GA4TrackInternalSearch(
                        searchResultsTitle ?? '',
                        searchType,
                        pageType,
                      );
                    executeSearch(searchResultsTitle, 1, false, searchType);
                    trackButtonClick?.(searchResultsTitle, 'search_term', []);
                  }}
                >
                  {searchResultsTitle}
                </Link>
                &rdquo;
              </ResultsSuggestion>
            </>
          )}
          {/* case 6: original query doesnt have many results so typo correction is suggested as an option*/}
          {!enoughResults && typoCorrection !== searchResultsTitle && (
            <>
              <ResultsTitle
                size={4}
                weight={'medium'}
                renderAs={'h1'}
                tabIndex={-1}
                ref={resultsTitle}
              >
                {`${totalHits} ${$t('SEARCH_RESULTS_FOR')} "${searchResultsTitle}"`}
              </ResultsTitle>
              <ResultsSuggestion size={7} weight={'book'}>
                {$t('CDS_SEARCH_NOT_FOUND_2')}
                <Link
                  underlined={true}
                  renderAs={'button'}
                  weight={'medium'}
                  size={7}
                  onClick={() => {
                    const searchType = 'Typo suggestion';
                    enableGA4Tracking &&
                      GA4TrackInternalSearch(
                        typoCorrection ?? '',
                        searchType,
                        pageType,
                      );
                    executeSearch(typoCorrection ?? '', 1, false, searchType);
                    trackButtonClick?.(typoCorrection ?? '', 'search_term', []);
                  }}
                >
                  {typoCorrection}
                </Link>
                &rdquo;
              </ResultsSuggestion>
            </>
          )}
        </VStack>
      );
    } else {
      // case 1: default - normal search results
      resultText = (
        <VStack
          flexDirection={'row'}
          justifyContent={'space-between'}
          paddingBottom={{ base: 24, md: 32 }}
          spacing="4px"
        >
          <ResultsTitle
            size={4}
            weight={'medium'}
            renderAs={'h1'}
            tabIndex={-1}
            ref={resultsTitle}
          >
            {`${totalHits} ${$t('SEARCH_RESULTS_FOR')} "${searchResultsTitle}"`}
          </ResultsTitle>
          {referringPortal !== undefined && (
            <StyledIconLink
              iconPosition="right"
              icon={interaction___close}
              animationDirection="left"
              href={referringPortal}
            >
              {$t('SEARCH_CLOSE')}
            </StyledIconLink>
          )}
        </VStack>
      );
    }
  } else {
    // case 3 and 4: no search results
    resultText = (
      <>
        <VStack
          flexDirection={{ base: 'column', md: 'row-reverse' }}
          justifyContent={'space-between'}
          paddingBottom={{ base: '$layout-2', md: '$layout-3' }}
        >
          <VStack alignItems={'center'}>
            <SearchInteractionIcon />
          </VStack>
          <VStack
            paddingBottom={4}
            marginTop={{ base: 0, md: '$layout-5' }}
            marginBottom={{ base: 0, md: '$layout-5' }}
          >
            <ResultsTitle
              size={4}
              weight={'medium'}
              renderAs={'h1'}
              textAlign={'left'}
              tabIndex={-1}
              ref={resultsTitle}
            >
              {`${$t('CDS_SEARCH_NOT_FOUND_1')} "${searchResultsTitle}"`}
            </ResultsTitle>
            <VStack>
              <Typography size={7} weight={'book'}>
                {latestSuggestion.length > 0 ? (
                  <>
                    {/* case 3: last suggestion is offered as an option */}
                    {$t('CDS_SEARCH_NOT_FOUND_2')}
                    &ldquo;
                    <Link
                      renderAs={'button'}
                      underlined={true}
                      weight={'medium'}
                      size={7}
                      onClick={() => {
                        enableGA4Tracking &&
                          GA4TrackInternalSearch(
                            latestSuggestion,
                            'Typo suggestion',
                            pageType,
                            1,
                          );
                        executeSearch(
                          latestSuggestion,
                          1,
                          false,
                          'Search suggestion',
                        );
                        trackButtonClick?.(latestSuggestion, 'search_term', []);
                      }}
                    >
                      {latestSuggestion}
                    </Link>
                    &rdquo; ?
                  </>
                ) : (
                  // case 4: no last sugg to offer
                  $t('CDS_SEARCH_NOT_FOUND_3')
                )}
              </Typography>
            </VStack>
          </VStack>
        </VStack>
        <VStack
          role="separator"
          marginBottom={{ base: '$layout-2', md: '$layout-3' }}
          marginTop={{ base: '$layout-2', md: 0 }}
        >
          <Underline disabled css={{ width: '100%' }} />
        </VStack>
        <VStack spacing={{ base: '$component-2', md: '$layout-1' }}>
          <Typography size={7} weight={'book'} color={'helper'}>
            {$t('COMMONLY_SEARCHED')}
          </Typography>
          <SuggestionChips
            mostSearchedTerms={topSearchTerms}
            pageType={pageType}
            executeSearch={(query, searchType) =>
              executeSearch(query, 1, false, searchType)
            }
          />

          {showMostReadArticles && <TopArticles articles={mostReadArticles} />}
        </VStack>
      </>
    );
  }
  let topWidgets = null;
  const result = searchResults[0];
  const name = result?.name;
  const widgetsToRender = result?.widgetsToRender;
  const doesTopWidgetHaveIcon = result?.widget?.ctas[0].icon;
  const hasTopWidgets =
    areThereResults &&
    widgetsToRender.length > 0 &&
    isTopWidget(widgetsToRender) &&
    doesTopWidgetHaveIcon;

  if (hasTopWidgets) {
    const searchContext = createSearchEntity(
      searchResultsTitle,
      name,
      'result',
      page ?? null,
      searchResults,
      undefined, //results dont have suggestions
      widgetsToRender,
    );
    const articleSlug = '/' + result.slug?.match(/\/([^\/]+)\/?$/)?.[1];
    topWidgets = (
      <SearchCtx.Provider value={searchContext}>
        <WidgetRenderer
          articleUrl={articleSlug}
          ctas={result?.widget?.ctas ?? []}
          widgetsToRender={widgetsToRender}
        />
      </SearchCtx.Provider>
    );
  }

  const firstHalf = searchResults.slice(0, 5);
  const secondHalf = searchResults.slice(5, 10);

  const { chatState } = useChatStore(selectChatProps);

  return (
    <VStack alignItems={'center'}>
      <XColumnsGridItem columns={{ base: 12, md: 8 }}>
        <NoResultSpacing areThereResults={areThereResults}>
          {showBreadcrumbs && (
            // the ref is for an accessibility workaround
            <Breadcrumbs label={$t('HELP')}>
              <BreadcrumbsItem
                target="/"
                //yes, it looks dumb, but in the future the english version should be Service portal
                label={$t(homeBreadrumbKey as any)}
              />
              <BreadcrumbsItem
                target=""
                label={$t('SEARCH_RESULTS')}
                disabled
              />
            </Breadcrumbs>
          )}
          <VStack css={{ width: '100%' }}>
            {resultText}
            <VStack spacing={{ base: 24, md: 32 }}>
              {topWidgets}
              <VStack>
                {firstHalf &&
                  firstHalf.map((result, index) => {
                    const name = result?.name;
                    const widgetsToRender = result?.widgetsToRender;
                    const portalContext = createPortalEntity(
                      result.category ?? '',
                      result.name,
                    );

                    const searchContext = createSearchEntity(
                      searchResultsTitle,
                      name,
                      'result',
                      page ?? null,
                      searchResults,
                      undefined, //results dont have suggestions
                      widgetsToRender,
                    );

                    const href =
                      makeLink({
                        href: result?.slug,
                        absoluteUrl: true,
                      }) ?? '';

                    const atts = getBadgeAttributes(href, badgeRules, language);
                    const hasWidget =
                      (index > 0 || !hasTopWidgets) &&
                      widgetsToRender.length > 0 &&
                      isInlineWidget(widgetsToRender);
                    // const hasChatbotMsg = index === 4;

                    return (
                      <React.Fragment key={index}>
                        <StyledClickItem
                          href={href}
                          noBorderBottom={
                            hasWidget ||
                            (chatState.workgroupName !== undefined &&
                              index === 4)
                          }
                          //remove last borderbottom when nachkon
                          css={{
                            '& > div': {
                              borderBottom: showTopicPages
                                ? undefined
                                : '1px solid $petrol200',
                            },
                          }}
                          onClick={() => {
                            trackSearch?.('result_click', [
                              searchContext,
                              portalContext,
                            ]);
                            enableGA4Tracking &&
                              GA4TrackSearchResultClick(
                                searchResultsTitle,
                                name,
                                makeLink({ href }),
                                index + 1 + (page - 1) * 10,
                                searchType,
                                totalHits ?? 0,
                                page,
                                atts.title || '',
                              );
                          }}
                        >
                          <VStack css={{ zIndex: 1 }}>
                            <VStack spacing={8}>
                              <Box>
                                <BannerTextBadge look={atts.look}>
                                  {atts.title}
                                </BannerTextBadge>
                              </Box>
                              <StyledTypography size={6} weight="medium">
                                {name}
                              </StyledTypography>
                            </VStack>
                            <Typography size={7} color="helper" weight={'book'}>
                              {shorten(result.description ?? '', 160)}
                            </Typography>
                          </VStack>
                        </StyledClickItem>
                        {hasWidget && (
                          <SearchCtx.Provider value={searchContext}>
                            <InlineWidgetRenderer
                              ctas={result?.widget?.ctas ?? []}
                              widgetsToRender={widgetsToRender}
                            />
                          </SearchCtx.Provider>
                        )}
                      </React.Fragment>
                    );
                  })}
              </VStack>
            </VStack>

            {showTopicPages && <TopicPageList />}

            {chatState.workgroupName && (
              <VStack
                marginTop={{ base: '$layout-5', md: '$layout-6' }}
                marginBottom={{ base: '$layout-5', md: '$layout-6' }}
              >
                <ChatEntryPoint
                  headline={'Nicht die richtige Antwort gefunden?'}
                  text={
                    'In unserem Chat können Sie Ihr persönliches Anliegen schildern. Wenn Ihnen unser Chatbot nicht weiterhelfen kann, werden Sie automatisch an Servicemitarbeitende weiter geleitet.'
                  }
                  portalContext={createPortalEntity(
                    'Result page',
                    searchResultsTitle,
                  )}
                />
              </VStack>
            )}

            <VStack>
              {secondHalf &&
                secondHalf.map((result, index) => {
                  const name = result?.name;
                  const widgetsToRender = result?.widgetsToRender;
                  const portalContext = createPortalEntity(
                    result.category ?? '',
                    result.name,
                  );

                  const searchContext = createSearchEntity(
                    searchResultsTitle,
                    name,
                    'result',
                    page ?? null,
                    searchResults,
                    undefined, //results dont have suggestions
                    widgetsToRender,
                  );

                  const href =
                    makeLink({
                      href: result?.slug,
                      absoluteUrl: true,
                    }) ?? '';

                  const atts: BannerTextBadgeProps = getBadgeAttributes(
                    href,
                    badgeRules,
                    language,
                  );

                  return (
                    <React.Fragment key={index}>
                      <ClickItem
                        key={index}
                        href={href}
                        onClick={() => {
                          trackSearch?.('result_click', [
                            searchContext,
                            portalContext,
                          ]);
                          enableGA4Tracking &&
                            GA4TrackSearchResultClick(
                              searchResultsTitle,
                              name,
                              makeLink({ href }),
                              index + 6 + (page - 1) * 10,
                              searchType,
                              totalHits ?? 0,
                              page,
                              atts.title || '',
                            );
                        }}
                      >
                        <VStack css={{ zIndex: 1 }}>
                          <VStack spacing={8}>
                            <Box>
                              <BannerTextBadge look={atts.look}>
                                {atts.title}
                              </BannerTextBadge>
                            </Box>
                            <StyledTypography size={6} weight="medium">
                              {name}
                            </StyledTypography>
                          </VStack>
                          <Typography size={7} color="helper" weight={'book'}>
                            {shorten(result.description ?? '', 160)}
                          </Typography>
                        </VStack>
                      </ClickItem>
                    </React.Fragment>
                  );
                })}
            </VStack>
          </VStack>
        </NoResultSpacing>
      </XColumnsGridItem>
    </VStack>
  );
};

export const StyledTypography = styled(Typography, {
  position: 'relative',
  zIndex: 1,
});

const StyledClickItem = styled(ClickItem, {
  variants: {
    noBorderBottom: {
      true: {
        '& > div': {
          borderBottom: 'none !important',
        },
      },
      false: {},
    },
  },
});

const ResultsTitle = styled(Typography, {
  '&:focus': {
    outline: 'none',
  },
});

const ResultsSuggestion = styled(Typography, {
  marginBottom: 0,
  md: {
    marginBottom: '16px',
  },
});

const StyledIconLink = styled(IconLink, {
  display: 'none',
  '@sm': {
    display: 'flex',
  },
});
