import React from 'react';
import Autosuggest from 'react-autosuggest';
import { symbols___info, Typography } from '@neui/styleguide-commerzbank';
import { Box } from '@neui/layout';
import { useTranslation } from '@utils/i18n';
import { StyledBanner } from '@components/CdsStyledComponents';
import { BannerBadgeLook } from '@components/neui-components/atoms/BannerTextBadge';

import { Divider } from '../CdsSearch';

interface CdsSuggestionsContainerProps {
  containerProps: Autosuggest.RenderSuggestionsContainerParams['containerProps'];
  children: React.ReactNode;
  wide?: boolean;
  iconLook?: BannerBadgeLook;
  showDivider?: boolean;
}

export const CdsSuggestionsContainer: React.FC<
  CdsSuggestionsContainerProps
> = ({ containerProps, children, wide, iconLook, showDivider }) => {
  const { $t } = useTranslation();

  if (React.Children.count(children) === 0) {
    return <></>;
  }

  const childrenArray = React.Children.toArray(children);

  // const links = React.Children.count(children) > 1 && (
  //   <Box css={{ marginTop: 24 }}>{childrenArray[1]}</Box>
  // );
  return (
    <>
      {showDivider && <Divider aria-hidden="true" />}
      <Box
        {...containerProps}
        css={{
          paddingBottom: wide ? 28 : 0,
          paddingTop: wide ? 16 : 0,
        }}
      >
        <Box css={{ width: '100%' }} data-cy={'search-suggestions'}>
          {childrenArray[0]}
        </Box>
        <StyledBanner
          icon={symbols___info}
          variant="solid"
          css={{ marginTop: 16 }}
          look={iconLook}
        >
          <Typography size={6}>{$t('PERSONAL_DATA_DISCLAIMER')}</Typography>
        </StyledBanner>
      </Box>
    </>
  );
};
